import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { axiosClient } from "../../api/axiosClient";
import { useBoardDetail, useCateList } from "../../api/board";
import Header from "../../components/Header/Header";
import ProsemirrorEditor from "../../components/prosemirror/ProsemirrorEditor";
import { serializer } from "../../submodules/integration-editors/prosemirror/schemas";
import { cloudFrontFile, uploadImg } from "../../util";

export default function NoticeWrite() {
  const token = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const location = useLocation();
  const urlParam = new URLSearchParams(location.search);
  const boardType = urlParam.get("boardType") || "board";
  const { id } = useParams();
  const [subCate, setSubCate] = useState(2);
  const [title, setTitle] = useState("");
  const [contents, setContents] = useState("");
  const [fileLocation, setFileLocation] = useState([]);
  const view = useRef(null);

  const { data: cateList } = useCateList(boardType);
  const { data: modifyData } = useBoardDetail(id, boardType, { disabled: !id });

  useEffect(() => {
    if (!id || !modifyData) return;
    setSubCate(modifyData?.categoryId);
    setTitle(modifyData?.title);
    setContents(modifyData?.rawContent);
    setFileLocation(modifyData?.imageList);
  }, [modifyData, id]);

  const getHTMLStringFromContent = (content) => {
    if (!content) return;
    const wrap = document.createElement("div");
    const contentFragment = serializer?.serializeFragment(content);
    wrap.append(contentFragment);
    const html = wrap.innerHTML;
    wrap.remove();
    return html;
  };

  const submit = () => {
    const contentPM = view?.current?.state.doc;
    const htmlString = getHTMLStringFromContent(contentPM);

    const data = contentPM
      ? {
          title: title,
          categoryId: subCate,
          rawContent: htmlString,
          contentJson: contentPM?.toJSON() ?? "",
          anonymous: false,
          isNotice: true,
          imageList: fileLocation,
        }
      : {
          title: title,
          categoryId: subCate,
          rawContent: contents,
          anonymous: false,
          isNotice: true,
          imageList: fileLocation,
        };

    axiosClient(token)
      .post(`/admin/${boardType === "board" ? "boards" : boardType}`, data)
      .then(() => {
        localStorage.removeItem("autosave:pm-article:");
        localStorage.removeItem("autosave:pm-article-length:");
        alert("작성되었습니다.");
        navigate("/notice");
      })
      .catch(() => alert("에러가 발생하였습니다."));
  };

  const modify = () => {
    axiosClient(token)
      .put(`/admin/${boardType === "board" ? "boards" : boardType}/${id}`, {
        title: title,
        categoryId: subCate,
        rawContent: contents,
        anonymous: false,
        isNotice: true,
        imageList: fileLocation,
      })
      .then(() => {
        alert("수정되었습니다.");
        navigate("/notice");
      })
      .catch(() => alert("에러가 발생하였습니다."));
  };

  return (
    <>
      <Header />
      <div className="px-4 pb-20 inner">
        <div className="mb-16 page-tit">
          {boardType === "board" ? "게시판" : "Q&A"} 공지{id ? "수정" : "등록"}
        </div>
        <div className="flex items-center">
          <select
            className="w-48 mr-5"
            onChange={(e) => setSubCate(parseInt(e.target.value))}
            value={subCate}>
            {cateList &&
              cateList.categories?.map((c) => (
                <option value={c?.id}>{c?.title}</option>
              ))}
          </select>
        </div>
        <section className="mt-7 lg:mt-4 lg:space-y-4">
          <div>
            <input
              type="text"
              placeholder="제목을 입력해주세요"
              className="focus:outline-none placeholder:text-[#ccc] placeholder:font-semibold placeholder:text-xl lg:placeholder:font-medium lg:shadow-custom lg:rounded-lg px-4 py-3 border-b border-[#EDEDED] w-full text-[#333] font-semibold text-xl"
              onChange={(e) => setTitle(e.target.value)}
              defaultValue={title}
            />
          </div>

          {modifyData ? (
            <>
              {modifyData?.contentJson ? (
                <ProsemirrorEditor
                  SavedContentJson={modifyData?.contentJson}
                  IsAdmin={token?.user_id === "admin"}
                  ref={view}
                />
              ) : (
                <textarea
                  placeholder="내용을 입력해주세요"
                  className="focus:outline-none px-4 py-3 placeholder:text-base lg:placeholder:font-medium font-semibold placeholder:text-[#ccc] text-[#333] w-full lg:shadow-custom lg:rounded-lg lg:h-[582px]"
                  onChange={(e) => setContents(e.target.value)}
                  defaultValue={contents}
                />
              )}
            </>
          ) : (
            <ProsemirrorEditor IsAdmin={true} ref={view} />
          )}
        </section>
        {modifyData && !modifyData.contentJson && (
          <section className="sm:hidden lg:block">
            <div className="flex items-center space-x-2 mt-[15px] px-4">
              <label className="flex items-center justify-between cursor-pointer border border-[#666] rounded-full w-[100px] h-[33px] py-2 px-3 text-[#666] font-medium">
                <input
                  type="file"
                  className="hidden"
                  onChange={(e) =>
                    uploadImg(
                      e,
                      token,
                      `/article/${boardType}/upload-image`
                    ).then((url) =>
                      setFileLocation([...fileLocation, cloudFrontFile(url)])
                    )
                  }
                />
                <i className="text-base ii-camera-vintage-outline"></i>
                <div className="text-xs">이미지 첨부</div>
              </label>
              <div className="text-[13px] text-[#666] font-medium">
                이미지 파일은 각각 최대 20MB, 총 50개까지 업로드 가능합니다.
              </div>
            </div>
            {fileLocation.length > 0 && (
              <div className="sm:hidden lg:block mt-7 w-full h-[120px]">
                <ul className="flex items-center space-x-3">
                  {fileLocation.map((image, idx) => {
                    return (
                      <li key={idx} className="w-28 h-28 bg-[#f5f5f5] relative">
                        <img src={image} alt="이미지" />
                        <button
                          onClick={() => {
                            setFileLocation(
                              fileLocation.filter((item) => item !== image)
                            );
                          }}
                          className="absolute w-5 h-5 top-0 right-0 bg-[#fff] text-sm">
                          X
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </section>
        )}
        <div className="flex items-center justify-end mt-8 space-x-2">
          <button className="white" onClick={() => navigate(-1)}>
            취소
          </button>
          {id ? (
            <button className="main" onClick={modify}>
              수정
            </button>
          ) : (
            <button className="main" onClick={submit}>
              등록
            </button>
          )}
        </div>
      </div>
    </>
  );
}
